.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rowC{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
}

.item{
  display:flex;
  flex:1;
  flex-direction:column;
  padding:0 10px 10px 0;
}

.center-fit {
  max-width: 10%;
  max-height: 10%;
}
